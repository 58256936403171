<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	DrawerClose,
	IconButton,
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTrigger,
	DialogTitle,
} from '@laam/ui/base';
import {
	PhCheckCircle,
	PhCircle,
	PhPlus,
	PhPlusCircle,
} from '@phosphor-icons/vue';
import LazyImage from '../LazyImage.vue';
import {
	useAddProductsToWishlist,
	type AddToWishlistPayload,
	type Wishlist,
	type WishlistProduct,
} from '~/data/wishlist';
import {
	useAddProductsToWishlist as useAddProductsToWishlistV2,
	type Wishlist as WishlistV2,
} from '~/data/wishlist-v2';

import { useWishlistStore } from '~/stores/wishlist';
import { useMutationState } from '@tanstack/vue-query';
import CreateWishlist from './CreateWishlist.vue';
import { createReusableTemplate } from '@vueuse/core';

interface AddToListProps {
	title: string;
	fromListId?: number;
	productsToMove?: number[];
	open: boolean;
}
const props = withDefaults(defineProps<AddToListProps>(), {
	title: 'Add to list',
	open: false,
	fromListId: undefined,
	productsToMove: undefined,
});

const emit = defineEmits<{
	(e: 'closed'): void;
}>();

const setEditMode = inject('setEditMode') as (mode: boolean) => void;

const productsToMove = toRef(props, 'productsToMove');
const open = ref(props.open);

const [UseTemplate, AddToListForm] = createReusableTemplate();

const WishlistStore = useWishlistStore();
const LoganStore = useLoganStore();
const { wishlists, wishlistsV2 } = storeToRefs(WishlistStore);

const { isWishlistV2Enabled } = useStoreAddons();

const { addProductsToWishlist } = useAddProductsToWishlist();
const { addProductsToWishlist: addProductsToWishlistV2 } =
	useAddProductsToWishlistV2();

const reducedLists = computed(() => {
	const list = isWishlistV2Enabled.value ? wishlistsV2.value : wishlists.value;
	if (!list) {
		return [];
	} else if (props.fromListId) {
		return list.otherLists.filter((list) => list.id !== props.fromListId);
	} else {
		return list.otherLists;
	}
});

const getImage = (list: Wishlist | WishlistV2) => {
	if (!isWishlistV2Enabled.value) {
		const products = list.products as { [key: string]: WishlistProduct };
		const product = products[Object.keys(products)[0]!];
		return product?.featuredImage.src;
	} else {
		return '';
	}
};

const isInList = (list: Wishlist | WishlistV2) => {
	if (!isWishlistV2Enabled.value) {
		const products = list.products as { [key: string]: WishlistProduct };
		return productsToMove.value!.every((product) => products[product]);
	} else {
		const products = (list as WishlistV2).products;
		return productsToMove.value!.every((product) => products.includes(product));
	}
};

const handleAddToList = (listId: number) => {
	if (!isWishlistV2Enabled.value) {
		addProductsToWishlist({
			logan_id: LoganStore.loganId,
			wishlist_id: listId,
			product_shopify_ids: productsToMove.value!,
		});
	} else {
		addProductsToWishlistV2({
			id: listId,
			products: productsToMove.value!,
		});
	}
};

const state = useMutationState({
	filters: { mutationKey: ['add-products-to-wishlist'] },
	select: (mutation) => ({
		id: (mutation.state.variables as AddToWishlistPayload)?.wishlist_id,
		status: mutation.state.status,
	}),
});

watch(open, (open) => {
	if (!open) {
		emit('closed');
		if (setEditMode) setEditMode(false);
	}
});

const isRequestPending = (list: Wishlist | WishlistV2) => {
	return state.value.find((s) => s.id === list.id)?.status === 'pending';
};

const getTotalProductsCount = (list: Wishlist | WishlistV2) => {
	if (!isWishlistV2Enabled.value) {
		return (list as Wishlist).totalProductsCount;
	} else {
		return (list as WishlistV2).total_products_count;
	}
};
</script>
<template>
	<UseTemplate>
		<CreateWishlist>
			<button class="flex items-center gap-xl">
				<div class="bg-white border border-gray-200 p-2xl rounded-xl">
					<PhPlus size="28" />
				</div>
				<p class="text-sm font-semibold grow">Create a new list</p>
			</button>
		</CreateWishlist>
		<!-- Other lists -->
		<div
			v-for="list in reducedLists"
			:key="list.id"
			class="flex items-center gap-xl add_to_list_option"
		>
			<!-- Image -->
			<div class="!min-h-[56px] h-[56px] aspect-square">
				<LazyImage
					v-if="getImage(list)"
					:src="getImage(list)!"
					alt="Product image"
					class="w-full h-full rounded-medium"
					width="56"
				/>
				<img
					v-else
					src="/product_img_placeholder.png"
					alt="Product image"
					class="h-full rounded-medium"
					width="56"
				/>
			</div>
			<!-- List Attrs -->
			<div class="grow">
				<!-- List Name -->
				<h1 class="font-semibold text-gray-800 text-md">
					{{ list.name }}
				</h1>
				<!-- Attrs -->
				<div class="flex text-sm text-gray-600 gap-md">
					<span>{{ list.public ? 'Public' : 'Private' }}</span>
					<div class="flex items-center">
						<PhCircle size="3" weight="fill"></PhCircle>
					</div>
					<span>{{ getTotalProductsCount(list) }} item(s)</span>
				</div>
			</div>

			<!-- Action Button -->
			<IconButton
				v-if="isRequestPending(list) || isInList(list)"
				variant="icon"
				size="2xl"
				class="!p-md"
			>
				<PhCheckCircle :weight="'fill'" />
			</IconButton>
			<IconButton
				v-else
				variant="icon"
				size="2xl"
				class="!p-md"
				@click="handleAddToList(list.id)"
			>
				<PhPlusCircle />
			</IconButton>
		</div>
	</UseTemplate>
	<Drawer v-if="isMobileView()" v-model:open="open">
		<DrawerTrigger as-child>
			<slot />
		</DrawerTrigger>
		<DrawerContent class="add_to_list_drawer">
			<DrawerClose />
			<DrawerHeader>
				<DrawerTitle class="font-semibold text-gray-800 text-md">
					{{ title }}
				</DrawerTitle>
			</DrawerHeader>
			<div class="px-3xl space-y-3xl pb-7xl">
				<AddToListForm />
			</div>
		</DrawerContent>
	</Drawer>
	<Dialog v-else :open="open" @update:open="open = !open">
		<DialogTrigger as-child>
			<slot />
		</DialogTrigger>
		<DialogContent class="add_to_list_dialog">
			<DialogClose @click="open = false" />
			<DialogHeader>
				<DialogTitle>
					{{ title }}
				</DialogTitle>
			</DialogHeader>
			<div class="px-3xl space-y-3xl pb-7xl">
				<AddToListForm />
			</div>
		</DialogContent>
	</Dialog>
</template>
